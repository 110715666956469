import React from "react";
import {
  GanttComponent,
  Inject,
  Selection,
  Toolbar,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-gantt";
import "./Gantt.css";

export const Gantt = ({ data }) => {
  const taskFields = {
    id: "ItemID",
    boardId: "BoardID",
    name: "TaskName",
    startDate: "StartDate",
    endDate: "EndDate",
    duration: "Duration",
    progress: "Progress",
    dependency: "Predecessor",
    child: "subtasks",
  };
  const labelSettings = {
    leftLabel: "TaskName",
  };

  const ganttBtn = (props) => {
    const url = `${window.location.ancestorOrigins[0]}/boards/${props.taskData.BoardID}/pulses/${props.taskData.ItemID}`;
    return (
      <button
        className="ganttBtn"
        onClick={() => {
          window.open(url, "_blank", "noopener,noreferrer");
        }}
      >
        Open
      </button>
    );
  };

  const taskbarTemplate = (props) => {
    return (
      <div
        className="e-gantt-child-taskbar-inner-div e-gantt-child-taskbar"
        style={{ height: "100%", backgroundColor: "#7f8ee0" }}
      >
        <div
          className="e-gantt-child-progressbar-inner-div e-gantt-child-progressbar"
          style={{
            width: props.ganttProperties.progressWidth + "px",
            height: "100%",
          }}
        ></div>
        <span
          className="e-task-label"
          style={{
            position: "absolute",
            fontSize: "12px",
            color: "white",
            top: "4px",
            left: "-5px",
          }}
        >
          {props.Progress}%
        </span>
      </div>
    );
  };

  const parentTaskbarTemplate = (props) => {
    return (
      <div
        className="e-gantt-parent-taskbar-inner-div e-gantt-parent-taskbar"
        style={{ height: "100%", backgroundColor: "#8c8c8cde" }}
      >
        <div
          className="e-gantt-parent-progressbar-inner-div e-row-expand e-gantt-parent-progressbar"
          style={{ width: props.ganttProperties.progressWidth + "px", height: "100%" }}
        ></div>
        <span
          className="e-task-label"
          style={{
            position: "absolute",
            fontSize: "12px",
            color: "white",
            top: "4px",
            left: "-5px",
          }}
        >
          {props.Progress}%
        </span>
      </div>
    );
  };

  return (
    <div className="control-section">
      <GanttComponent
        id="Default"
        dataSource={data.items}
        treeColumnIndex={1}
        taskFields={taskFields}
        labelSettings={labelSettings}
        toolbar={["ZoomIn", "ZoomOut"]}
        taskbarTemplate={taskbarTemplate}
        parentTaskbarTemplate={parentTaskbarTemplate}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="nav"
            headerText="Link"
            template={ganttBtn}
            width="90px"
          ></ColumnDirective>
          <ColumnDirective
            field="TaskName"
            headerText="Job Name"
            width="250"
            textAlign="Left"
            clipMode="EllipsisWithTooltip"
          ></ColumnDirective>
          <ColumnDirective field="StartDate"></ColumnDirective>
          <ColumnDirective field="Duration"></ColumnDirective>
          <ColumnDirective field="Progress"></ColumnDirective>
          <ColumnDirective field="Predecessor"></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Selection, Toolbar]} />
      </GanttComponent>
    </div>
  );
};
