import { chunkArray, sleep } from "../Util/Monday.util";

export const getItemById = async (
  selectedItemId,
  monday,
  setSelectedItemData,
  setError,
  setLoading
) => {
  try {
    console.log("Getting selected item data...");
    const query = `
      query ($selectedItemId: [ID!]) {
        items(ids: $selectedItemId, exclude_nonactive:true) {
          column_values {
            id
            column {
              title
              type
            }
            ... on BoardRelationValue{
                linked_item_ids
              }
            value
            text
            type
          }
        }
      }
    `;
    const variables = { selectedItemId };
    const resp = await requestMondayApi(query, variables, monday);
    setSelectedItemData(resp);
  } catch (error) {
    //Get cached data for this particular workspace
    setLoading(false);
    setError("Failed to retrieve item data");
    setSelectedItemData(undefined);
    console.error(error, "There was an error fetching the selected item!");
    return;
  }
};

export const getItemsById = async (itemIds, monday) => {
  try {
    const data = [];
    const chunkedItemIds = itemIds.length > 100 ? chunkArray(itemIds, 100) : [itemIds];
    for (const chunkedItemId of chunkedItemIds) {
      let index = 0;
      let finished = false;

      while (!finished) {
        console.log("Getting items...", index);
        index++;
        const query = `
        query ($chunkedItemId: [ID!]!) {
          complexity{ 
            query
          }
          items(ids: $chunkedItemId, limit: 100, page: ${index}, exclude_nonactive:true) {
            id
            name
            column_values {
              column {
                title
                type
              }
              ... on BoardRelationValue{
                linked_item_ids
              }
              ... on TimelineValue {
                from
                to
              }
              text
              value
            }
            board{
              id
            }
          }
        }
          `;
        const variables = { chunkedItemId };
        const resp = await requestMondayApi(query, variables, monday);
        console.log("Query complexity", resp.data.complexity.query);
        if (!resp.data?.items || resp.data?.items?.length === 0) {
          finished = true;
        } else {
          resp.data.items.forEach((item) => data.push(item));
        }
      }
    }
    return data;
  } catch (error) {
    console.error(error, "There was an error fetching the items!");
    return;
  }
};

//Function for requesting mutations and queries through monday.com that has built in retry mechanism
const SLEEP_TIME = 35;
export const requestMondayApi = async (query, variables = {}, monday, retries = 0) => {
  if (retries > 5) throw new Error("Failed too many times");

  try {
    return await monday.api(query, { variables });
  } catch (error) {
    console.log(`Error while requesting monday api, waiting ${SLEEP_TIME} seconds.`);
    console.log(error);
    await sleep(SLEEP_TIME);

    return await requestMondayApi(query, variables, monday, retries + 1);
  }
};
