import { Collapse } from "antd";
import "./Monday.util.css";
import { getItemsById } from "../Services/Monday.service";
const { Panel } = Collapse;

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const sortByDate = (a, b) => {
  const timelineA = a.column_values.find(
    (cv) => cv.column.title === "Timeline" && cv.column.type === "timeline"
  )?.text;
  const timelineB = b.column_values.find(
    (cv) => cv.column.title === "Timeline" && cv.column.type === "timeline"
  )?.text;

  if (!timelineA) return 1;
  if (!timelineB) return -1;

  const [startDateA, endDateA] = timelineA.split(" - ");
  const [startDateB, endDateB] = timelineB.split(" - ");

  if (startDateA < startDateB) return -1;
  if (startDateA > startDateB) return 1;
  if (endDateA < endDateB) return -1;
  if (endDateA > endDateB) return 1;

  return 0;
};

const renderChildrenItems = (connectedItems) => {
  const nodes = connectedItems.map((item, i) => {
    if (item?.children?.props?.children?.length) {
      return (
        <Panel collapsible="icon" header={item.label} key={i} item={item.children} />
      );
    }
    return <Panel collapsible="icon" showArrow={false} header={item.label} key={i} />;
  });
  return <Collapse bordered={false}>{nodes}</Collapse>;
};

const prepareGanttData = async (data, monday, processedIds = new Map()) => {
  console.log("Preparing Gantt Data...");
  data.sort(sortByDate);
  const preparedData = {};

  const items = [];
  for (const item of data) {
    if (processedIds.get(item.id)) {
      console.log("Item already processed, skipping", item.id);
      continue;
    }

    processedIds.set(item.id, true);
    const itemAttrs = {
      ItemID: item.id,
      TaskName: item.name,
      BoardID: item.board.id,
    };

    const timeline = item.column_values.find(
      (cv) => cv.column.title === "Timeline" && cv.column.type === "timeline"
    );
    if (timeline) {
      const { from, to } = timeline;
      if (from && to) {
        itemAttrs.StartDate = new Date(from);
        itemAttrs.EndDate = new Date(to);
      }
    }

    const durationDays = item.column_values.find(
      (cv) => cv.column.title === "Duration Days" && cv.column.type === "numbers"
    );
    if (durationDays) {
      itemAttrs.Duration = durationDays.text;
    }

    const percentCompleted = item.column_values.find(
      (cv) => cv.column.title === "% Complete" && cv.column.type === "numbers"
    )?.text;
    if (percentCompleted) {
      itemAttrs.Progress = percentCompleted;
    }

    const dependency = item.column_values.find(
      (cv) => cv.column.title === "Dependent On" && cv.column.type === "dependency"
    );
    if (dependency && dependency.value) {
      const dependentItems = JSON.parse(dependency.value).linkedPulseIds;
      if (dependentItems?.length) {
        itemAttrs.Predecessor = dependentItems
          .map((item) => item.linkedPulseId)
          .join(", ");
      }
    }

    const linkChildrenText = item.column_values.find(
      (cv) => cv.column?.title === "LINK - Children" && cv.column?.type === "text"
    )?.text;

    const linkChildrenRelation = item.column_values.find(
      (cv) =>
        cv.column?.title === "LINK - Children" && cv.column?.type === "board_relation"
    )?.linked_item_ids;

    if (linkChildrenText) {
      const itemIds = linkChildrenText.split(",")?.map((item) => parseInt(item));
      const subItems = await getGanttSubItems(itemIds, monday, processedIds);
      itemAttrs.subtasks = subItems.items;
    }

    if (linkChildrenRelation && linkChildrenRelation.length) {
      const subItems = await getGanttSubItems(linkChildrenRelation, monday, processedIds);
      itemAttrs.subtasks = subItems.items;
    }

    items.push(itemAttrs);
  }
  preparedData.items = items;

  return preparedData;
};

const getGanttSubItems = async (itemIds, monday, processedIds) => {
  const parentItems = await getItemsById(itemIds, monday);
  const data = await prepareGanttData(parentItems, monday, processedIds);
  return data;
};

const filterBoardData = async (
  setGanttData,
  selectedItemData,
  setLoading,
  setColumnExists,
  monday
) => {
  const item = selectedItemData.data.items[0];
  const childrenIds = [];
  const childrenColumnBoardRelation = item.column_values.find(
    (col) => col.column.title === "LINK - Children" && col.type === "board_relation"
  );
  const childrenColumnText = item.column_values.find(
    (col) => col.column.title === "LINK - Children" && col.type === "text"
  );

  if (!childrenColumnBoardRelation && !childrenColumnText) {
    setColumnExists(false);
    setLoading(false);
    return;
  }

  if (childrenColumnText) {
    const childrenColumnTextItems = childrenColumnText?.text
      ?.split(",")
      ?.map((item) => parseInt(item));
    if (
      childrenColumnTextItems &&
      childrenColumnTextItems.length > 0 &&
      childrenColumnTextItems.every((item) => typeof item === "number" && !isNaN(item))
    ) {
      childrenIds.push(...childrenColumnTextItems);
    } else if (
      childrenColumnBoardRelation &&
      childrenColumnBoardRelation.linked_item_ids?.length > 0
    ) {
      childrenIds.push(...childrenColumnBoardRelation.linked_item_ids);
    }
  } else if (
    childrenColumnBoardRelation &&
    childrenColumnBoardRelation.linked_item_ids?.length > 0
  ) {
    childrenIds.push(...childrenColumnBoardRelation.linked_item_ids);
  }

  if (!childrenIds.length) {
    setLoading(false);
    return;
  }

  const parentItems = await getItemsById(childrenIds, monday);
  const ganttData = await prepareGanttData(parentItems, monday);
  setGanttData(ganttData);
  setLoading(false);
};

export const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const sleep = async (s) => {
  return new Promise((resolve) => setTimeout(resolve, s * 1000));
};

export { filterBoardData, renderChildrenItems };
